<template>
	<div class="login">
		<Topq></Topq>
		<div class="banner">
			<div :class="token!==null?'banner-left2':'banner-left'">
				<div class="banner-left-title">
					招聘商户版
				</div>
				<div class="banner-left-msg">
					一站式灵活用工招聘平台
				</div>
			</div>
			<div class="banner-right" v-if="!token">
				<div class="login-main">
					<span>登录</span>
					<div class="login-main-form">
						<div class="login-main-form-item">
							<img src="../assets/image/phone.png">
							<input type="text" v-model="username" placeholder="请先输入手机号" />
						</div>
						<div class="login-main-form-item">
							<img src="../assets/image/pas.png">
							<input type="password" v-model="password" placeholder="请输入密码" />
						</div>
						<div class="login-main-form-bottom">
							<div class="zhuce">
								没有账号？<span @click="register">注册</span>
							</div>
							<div class="gou">
								<el-checkbox v-model="checked"><span>记住密码</span></el-checkbox>
							</div>
						</div>
						<div class="login-main-botton" @click="submit">

							<span>登录</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="show">
			<div class="show-img">
				<img src="../assets/image/zhuanye.png">
			</div>
			<div class="show-content">
				<div class="show-item">
					<img src="../assets/image/s1.png">
					<div>在线招聘</div>
					<span>兼职、实习招聘</span>
					<span>3000万人才精准匹配</span>
					<span>快速招聘到岗</span>
				</div>
				<div class="show-item">
					<img src="../assets/image/s2.png">
					<div>海量人才</div>
					<span>每天众多活跃用户</span>
					<span>在平台报名兼职</span>
				</div>
				<div class="show-item">
					<img src="../assets/image/s3.png">
					<div>品牌宣传</div>
					<span>包含名企专区、企业</span>
					<span>推荐模版,为商家进行</span>
					<span>品牌和产品推广</span>
				</div>
				<div class="show-item">
					<img src="../assets/image/s4.png">
					<div>PRO招聘服务</div>
					<span>以客户需求为导向,以项目</span>
					<span>为建制,提供“一站式”</span>
					<span>定制化招聘服务</span>
				</div>

			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Topq from './common/Topq.vue'
	import Footer from '../components/common/Footer.vue'
	import md5 from 'js-md5'
	import $http from '../common/api/axios.js'
	import {
		login
	} from '../common/api/api.js'
	export default {
		data() {
			return {
				token: '',
				username: '',
				password: '',
				checked: true,
				loginshowMessage: false
			}
		},
		components: {
			Topq,
			Footer
		},
		created() {

		},
		mounted() {
			this.token = localStorage.getItem("token")
			this.getCookie();
		},
		methods: {
			loginaccess() {
				let that = this
				this.$store.dispatch('initDic').then(() => {
					$http.request({
						url: login,
						data: {
							"account": that.username,
							"password": md5(that.password),
						}
					}).then((res) => {
						if (res.code == 200) {
							that.loginshowMessage = false
							let token = res.data.loginToken;
							localStorage.setItem("token", token);
							let id = res.data.userId
							localStorage.setItem("id", id);
							let type = res.data.userType
							localStorage.setItem("type", type);
							this.$message({
								message: '登录成功!',
								type: 'success',
								duration:'2000'
							});
							window.setTimeout(function() {
								that.$router.push('./')
							}, 1000);
						} else if (res.code == 202) {
							that.loginshowMessage = true
						}
					}).catch(() => {

					})
				})
			},
			register() {
				this.$router.push('./pj-register')
			},
			submit() {
				let that = this;
				//判断复选框是否被勾选 勾选则调用配置cookie方法
				if (that.checked == true) {
					//传入账号名，密码，和保存天数3个参数
					that.setCookie(that.username, that.password, 7);
				} else {
					//清空Cookie
					that.clearCookie();
				}

				//与后端请求代码
				that.loginaccess()
			},
			//设置cookie
			setCookie(c_name, c_pwd, exdays) {
				var exdate = new Date(); //获取时间
				exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
				//字符串拼接cookie
				window.document.cookie = "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
				window.document.cookie = "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
			},
			//读取cookie
			getCookie: function() {
				if (document.cookie.length > 0) {
					var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
					for (var i = 0; i < arr.length; i++) {
						var arr2 = arr[i].split('='); //再次切割
						//判断查找相对应的值
						if (arr2[0] == 'userName') {
							this.username = arr2[1]; //保存到保存数据的地方
						} else if (arr2[0] == 'userPwd') {
							this.password = arr2[1];
						}
					}
				}
			},
			//清除cookie
			clearCookie: function() {
				this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
			},
		}
	}
</script>

<style scoped>
	.title-right-login {
		display: flex;
		align-items: center;
	}

	.title-right-login img {
		width: .39rem;
	}

	.title-right-login span {
		margin-left: .12rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.title-right {
		margin-left: .65rem;
	}

	.title-left span {
		margin-left: .64rem;
	}

	.tabItem {
		padding: 10px 0;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 600;
		color: #333333;
		text-decoration: none
	}

	.router-link-active {
		font-size: .18rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #E41D1D;
		border-bottom: .04rem solid #E41D1D;
	}

	.title {
		display: flex;
		align-items: center;
		width: 100%;
		height: .72rem;
		z-index: 999;
		font-size: .16rem;
		font-family: PingFang SC;
		color: #FFFFFF;
		cursor: pointer;
		box-shadow: 0px 3px 6px rgba(172, 172, 172, 0.1);
		transform: translate3d(0, 0, 0);
	}

	.title-logo {
		width: 1.5rem;
		margin-left: 3.6rem;
		margin-right: 4.24rem;
	}

	.title-logo img {
		width: 1.5rem;
	}

	.login {
		font-family: PingFang SC;
	}

	.banner {
		width: 19.2rem;
		height: 6.73rem;
		background: url(../assets/image/bg3.png) no-repeat;
		background-size: 100%;

	}

	.banner {
		position: relative;
	}

	.banner-left {
		position: relative;
		width: 100%;
	}

	.banner-left2 {
		position: relative;
		width: 100%;
		margin-left: 26%;
	}

	.banner-left-title {
		position: absolute;
		top: 2.6rem;
		left: 3.34rem;
		font-size: .48rem;
		font-weight: bold;
		line-height: 62px;
		color: #FFFFFF;
	}

	.banner-left-msg {
		position: absolute;
		top: 3.41rem;
		left: 3rem;
		font-size: .28rem;
		font-weight: 400;
		color: #FFFFFF;
	}

	.banner-right {
		position: absolute;
		right: 2.83rem;
		top: .86rem
	}

	.login-main {
		position: relative;
		width: 4.13rem;
		height: 4.34rem;
		background: rgba(255, 255, 255, 0.65);
		box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.43);
		border-radius: 10px;
	}

	.login-main>span {
		position: absolute;
		top: .5rem;
		left: 1.88rem;
		font-size: .18rem;
		font-weight: 600;
		color: #333333;
	}

	.login-main-form {
		position: absolute;
		top: 1.17rem;
		left: .34rem;
	}

	.login-main-form-item {
		margin-bottom: .2rem;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		padding-left: .16rem;
		width: 3.46rem;
		height: .5rem;
		background: #F5F5F5;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
		border-radius: 4px;
	}

	.login-main-form-item>img {
		margin-right: .1rem;
		width: .15rem;
	}

	.login-main-form-item>input {
		font-family: PingFang SC;
		font-size: .16rem;
		width: 2.7rem;
		height: .4rem;
		border: 0;
		background: #F5F5F5;
		outline-style: none;
	}

	.login-main-form-bottom {
		margin-bottom: .64rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.zhuce {
		font-size: .14rem;
		font-weight: 400;
		color: #666666;
	}

	.zhuce>span {
		color: #E41D1D;
		cursor: pointer;
	}

	.gou {
		display: flex;
		align-items: center;
	}

	.gou span {
		font-size: .14rem;
		font-weight: 400;
	}

	.login-main-botton {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 3.46rem;
		height: .5rem;
		background: #E41D1D;
		border-radius: 4px;
		cursor: pointer;
	}

	.login-main-botton span {
		font-size: .16rem;
		font-weight: 500;
		color: #FFFFFF;
	}

	.show {
		width: 19.2rem;
		height: 6.65rem;
	}

	.show-img {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: .7rem;
	}

	.show-img img {
		width: 3.6rem;
	}

	.show-content {
		display: flex;
		padding-top: 1rem;
		padding-left: 3.6rem;
	}

	.show-item {
		margin-right: .7rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 2.48rem;
		height: 2.48rem;
		background: #FFFFFF;
		border: 1px solid #F2F2F2;
		box-shadow: 0px 3px 12px rgba(199, 199, 199, 0.1);
		border-radius: 50%;
	}

	.show-item img {
		width: .72rem;
	}

	.show-item>div {
		font-size: .18rem;
		font-weight: 500;
		line-height: .5rem;
		color: #333333;
	}

	.show-item>span {
		display: inline-block;
		margin-bottom: .1rem;
		font-size: 12px;
		font-weight: 400;
		color: #666666;
		transform: scale(.84);
	}
</style>
